<template>
  <v-container fluid fill-height class="down-top-padding">
    <v-flex fill-height>
      <v-row>
        <v-col cols="12" sm="12" lg="12">
          <v-card>
            <v-toolbar flat class="subordinate--title">
              <h3 class="title text--darken-2 font-weight-regular">
                {{ $t("card.setting-officials") }}
              </h3>
              <h6 class="subtitle-2 font-weight-light">
                {{ $t("label.specify-the-current-officials") }}
              </h6>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text class="pa-5">
              <v-row class="mt-4" v-if="userIsSuperAdmin">
                <v-col cols="12" md="6" sm="12" class="mx-auto mt-4">
                  <v-autocomplete
                    :data-hint="$t('introjs.this-is-director-selector')"
                    data-hintposition="middle-middle"
                    data-position="bottom-right-aligned"
                    v-model="selectedUnit"
                    v-bind:items="listUnit"
                    v-bind:filter="unitFilter"
                    ref="selectunit"
                    v-bind:menu-props="{
                      closeOnClick: true,
                      closeOnContentClick: true,
                    }"
                    auto-select-first
                    hide-details="true"
                    item-text="name"
                    item-value="kodeunitbagian"
                    clearable
                    label="Unit"
                    placeholder="Unit"
                  >
                    <template slot="selection" slot-scope="data">
                      {{ data.item.name }}
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row v-if="listBagian.length > 0 && userIsSuperAdmin">
                <v-col cols="12" md="6" sm="12" class="mx-auto mt-4">
                  <v-autocomplete
                    :data-hint="$t('introjs.this-is-director-selector')"
                    data-hintposition="middle-middle"
                    data-position="bottom-right-aligned"
                    v-model="selectedBagian"
                    v-bind:items="listBagian"
                    v-bind:filter="bagianFilter"
                    v-bind:menu-props="{
                      closeOnClick: true,
                      closeOnContentClick: true,
                    }"
                    auto-select-first
                    hide-details="true"
                    item-text="name"
                    item-value="kodebagian"
                    clearable
                    v-bind:label="labelBagian"
                    v-bind:placeholder="labelBagian"
                  >
                    <template slot="selection" slot-scope="data">
                      {{ data.item.name }}
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row v-if="listType.length > 0">
                <v-col cols="12" md="6" sm="12" class="mx-auto mt-4">
                  <v-autocomplete
                    :data-hint="$t('introjs.this-is-director-selector')"
                    data-hintposition="middle-middle"
                    data-position="bottom-right-aligned"
                    v-model="selectedType"
                    v-bind:items="listType"
                    v-bind:filter="typeFilter"
                    v-bind:menu-props="{
                      closeOnClick: true,
                      closeOnContentClick: true,
                    }"
                    auto-select-first
                    hide-details="true"
                    item-text="name"
                    item-value="type"
                    clearable
                    v-bind:label="labelType"
                    v-bind:placeholder="labelType"
                  >
                    <template slot="selection" slot-scope="data">
                      {{ data.item.name }}
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row v-if="listKaryawan.length > 0">
                <v-col cols="12" md="6" sm="12" class="mx-auto mt-4">
                  <v-autocomplete
                    :data-hint="$t('introjs.this-is-director-selector')"
                    data-hintposition="middle-middle"
                    data-position="bottom-right-aligned"
                    v-model="selectedKaryawan"
                    v-bind:items="listKaryawan"
                    v-bind:filter="karyawanFilter"
                    v-bind:menu-props="{
                      closeOnClick: true,
                      closeOnContentClick: true,
                    }"
                    auto-select-first
                    hide-details="true"
                    item-text="nama"
                    item-value="nik"
                    clearable
                    v-bind:label="labelKaryawan"
                    v-bind:placeholder="labelKaryawan"
                  >
                    <template slot="selection" slot-scope="data">
                      {{ data.item.nama }}
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row v-if="selectedKaryawan" class="mt-4">
                <v-col cols="12" md="6" sm="12" class="mx-auto my-4">
                  <v-list
                    two-line
                    class="rounded border--thin height--100-percent"
                  >
                    <v-subheader class="text-uppercase text--bigger">{{
                      $t("label.permanent-official")
                    }}</v-subheader>

                    <v-list-item class="cursor--default">
                      <v-avatar class="mr-2">
                        <v-avatar
                          class="mr-2"
                          v-if="getSelectedKaryawan.avatar_path"
                        >
                          <img :src="getSelectedKaryawan.avatar_path" />
                        </v-avatar>
                      </v-avatar>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          getSelectedKaryawan.nama
                        }}</v-list-item-title>
                        <v-list-item-subtitle>{{
                          getSelectedKaryawan.jabatan
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
              <v-row v-if="getSelectedKaryawan">
                <v-col cols="12" md="6" sm="12" class="pa-4">
                  <v-list
                    two-line
                    class="rounded border--thin height--100-percent"
                  >
                    <v-subheader class="text-uppercase text--bigger">{{
                      $t("label.available-subtitute")
                    }}</v-subheader>
                    <div
                      class="table-responsive-container scroll__y-only"
                      style="max-height: 400px"
                    >
                      <div class="table-responsive">
                        <draggable
                          v-model="availableSubtitute"
                          group="people"
                          style="min-height: 100px; margin-bottom: 1rem"
                        >
                          <template v-for="item in availableSubtitute">
                            <v-list-item :key="item.id" class="cursor--grab">
                              <v-avatar class="mr-2">
                                <img :src="item.avatar_path" />
                              </v-avatar>
                              <v-list-item-content>
                                <v-list-item-title
                                  v-html="item.nama"
                                ></v-list-item-title>
                                <v-list-item-subtitle
                                  v-html="item.jabatan"
                                ></v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </draggable>
                      </div>
                    </div>
                  </v-list>
                </v-col>
                <v-col cols="12" md="6" sm="12" class="pa-4">
                  <v-list
                    two-line
                    class="rounded border--thin height--100-percent"
                  >
                    <v-subheader class="text-uppercase text--bigger">{{
                      $t("label.selected-as-substitute")
                    }}</v-subheader>
                    <div
                      class="table-responsive-container scroll__y-only"
                      style="max-height: 400px"
                    >
                      <div class="table-responsive">
                        <draggable
                          v-model="selectedSubtitute"
                          group="people"
                          style="min-height: 100px; margin-bottom: 1rem"
                        >
                          <template v-for="item in selectedSubtitute">
                            <v-list-item :key="item.id" class="cursor--grab">
                              <v-avatar class="mr-2">
                                <img :src="item.avatar_path" />
                              </v-avatar>
                              <v-list-item-content>
                                <v-list-item-title
                                  v-html="item.nama"
                                ></v-list-item-title>
                                <v-list-item-subtitle
                                  v-html="item.jabatan"
                                ></v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </draggable>
                      </div>
                    </div>
                  </v-list>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="mt-1 d-flex justify-end">
                  <v-btn
                    :disabled="
                      !selectedKaryawan &&
                      (!availableSubtitute || availableSubtitute.length < 1)
                    "
                    class="ma-2"
                    outlined
                    color="primary"
                    @click="saveChange"
                  >
                    <v-icon>{{ iconSave }}</v-icon>
                    {{ $t("button.save") }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-flex>
    <v-overlay :value="axiosLoading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
      <p class="mt-3">{{ $t("loader.please-wait") }}</p>
    </v-overlay>
  </v-container>
</template>
<script>
import { mdiContentSaveOutline, mdiDatabaseSync, mdiHelpCircle } from "@mdi/js";
// import Avatar from "vue-avatar-component";
// import Sortable from 'sortablejs/modular/sortable.core.esm.js';
import draggable from "vuedraggable";
import { mapActions, mapState } from "vuex";
export default {
  name: "user-mapping",
  components: {
    draggable,
    // Avatar,
    // Sortable
  },
  data() {
    return {
      iconSave: mdiContentSaveOutline,
      iconSync: mdiDatabaseSync,
      iconQuestion: mdiHelpCircle,
      selectedUnit: undefined,
      selectedBagian: undefined,
      selectedType: undefined,
      selectedKaryawan: undefined,
      selectedUpline: undefined,
      selectedSubtitute: undefined,
      availableSubtitute: [],
      labelBagian: this.$t("label.department"),
      labelKaryawan: this.$t("label.employee"),
      labelType: this.$t("label.elemen-user-types"),
    };
  },
  created() {
    this.resetAll();
    if (this.userIsSuperAdmin) {
      return this.loadUnits();
    }
    this.selectedUnit = this.currentUser.kode_unit;

    // this.loadKaryawan(this.currentUser.kode_unit, this.currentUser.kode_unit.kode_bagian);
  },
  watch: {
    selectedUnit(val) {
      if (!val) return;
      this.resetAll();
      this.selectedBagian = undefined;
      if (this.userIsSuperAdmin) {
        if (val == "00") {
          this.getBagians({ kodeUnit: val });
        } else {
          this.getTypes({
            kodeUnit: val,
            kodeBagian: "",
            without: "KRANI,SEKRETARIS,KARPIM,KARPIM_KANPUS",
          });
        }
      } else {
        if (val == "00") {
        this.selectedBagian = this.currentUser.kode_bagian;
        }
        else {
           this.selectedBagian = "ALL";
        }
      }
    },
    selectedBagian(val) {
      this.selectedType = undefined;
      this.selectedKaryawan = undefined;
      this.selectedSubtitute = undefined;
      // this.availableSubtitute = [];
      this.setTypes(undefined);
      this.setKaryawans(undefined);
      this.setPenggantiTersedia(undefined);
      this.setPenggantiTerpilih(undefined);
      if (!val) return;
      if (val =="ALL") {
        this.getTypes({
          kodeUnit: this.selectedUnit,
          kodeBagian: "",
          without: "KRANI,SEKRETARIS,KARPIM,KARPIM_KANPUS",
        });
      }
      else {
          this.getTypes({
          kodeUnit: this.selectedUnit,
          kodeBagian: val,
          without: "KRANI,SEKRETARIS,KARPIM,KARPIM_KANPUS",
        });
      }
    },
    selectedType(val) {
      // this.availableSubtitute = [];
      this.selectedKaryawan = undefined;
      this.setKaryawans(undefined);
      this.setPenggantiTersedia(undefined);
      this.setPenggantiTerpilih(undefined);
      this.selectedSubtitute = undefined;
      if (!val) return;
      this.getKaryawans({
        kodeUnit: this.selectedUnit,
        kodeBagian: this.selectedBagian,
        type: val,
      });
    },
    selectedKaryawan(val) {
      this.selectedSubtitute = undefined;
      // this.availableSubtitute = [];
      this.setPenggantiTersedia(undefined);
      this.setPenggantiTerpilih(undefined);
      if (!val) return;
      this.getPenggantiTerpilih({
        nikPjt: val,
      });
    },
    penggantiTerpilih(val) {
      // this.availableSubtitute = [];
      if (!this.selectedKaryawan) return;
      this.selectedSubtitute = val;
      setTimeout(() => {
        this.getPenggantiTersedia({
          kodeUnit: this.selectedUnit,
          kodeBagian: this.selectedBagian,
          nikPjt: this.selectedKaryawan,
          nikPjs: val[0].nik,
          userTypeElemen: this.selectedType,
        });
      }, 200);
    },
    penggantiTersedia(val, oldVal) {
      if (val === oldVal) return;
      if (!val || val.length < 1) return (this.availableSubtitute = []);
      this.availableSubtitute = val;
    },
  },
  computed: {
    ...mapState("resources", [
      "units",
      "bagians",
      "karyawans",
      "types",
      "axiosLoading",
      "penggantiTersedia",
      "penggantiTerpilih",
    ]),
    ...mapState("user", ["currentUser"]),
    userIsSuperAdmin() {
      if (!this.currentUser) return false;
      return this.currentUser.superadmin === true;
    },
    listUnit() {
      if (!this.units) return [];
      return this.units;
    },
    listBagian() {
      if (!this.bagians) return [];
      return this.bagians;
    },
    listType() {
      if (!this.types) return [];
      return this.types;
    },
    listKaryawan() {
      if (!this.karyawans) return [];
      return this.karyawans;
    },
    getSelectedKaryawan() {
      if (!this.karyawans || !this.selectedKaryawan) return undefined;
      let result = this.karyawans.filter(
        (item) => item.nik == this.selectedKaryawan
      );
      return result[0];
    },
    selectedUplineType() {
      if (!this.selectedType) return undefined;
      let result = this.types.filter((item) => item.type == this.selectedType);
      if (result.length > 0) return parseInt(result[0].level);
      return undefined;
    },
    // dataIsChanged(){
    //   if (!this.bawahanTersedia && !this.bawahanTerpilih) return false;
    //   if (!this.availableSubtitute && !this.selectedSubtitute) return false;
    //   if ((this.bawahanTersedia && this.bawahanTersedia.length === this.availableSubtitute.length) && (this.bawahanTerpilih && this.bawahanTerpilih.length === this.selectedSubtitute.length))  return false;
    //   return true;
    // }
    // selectedSubtitute: {
    //   get() {
    //     return this.bawahanTerpilih;
    //   },
    //   set(val) {
    //     this.$store.commit("resources/SET_BAWAHAN_TERPILIH", val);
    //   },
    // },
    // availableSubtitute: {
    //   get() {
    //     return this.bawahanTersedia;
    //   },
    //   set(val) {
    //     this.$store.commit("resources/SET_BAWAHAN_TERSEDIA", val);
    //   },
    // },
  },
  methods: {
    ...mapActions("resources", [
      "getUnits",
      "getBagians",
      "getKaryawans",
      "getTypes",
      "setUnits",
      "setBagians",
      "setTypes",
      "setJabatans",
      "setKaryawans",
      "setRoles",
      "getPenggantiTersedia",
      "getPenggantiTerpilih",
      "setPenggantiTersedia",
      "setPenggantiTerpilih",
      "updatePjs",
    ]),
    saveChange() {
      if (!this.selectedSubtitute || !this.availableSubtitute) return;
      let arrNik = this.mapNikBawahan(this.selectedSubtitute);

      let formData = {
        nik: arrNik,
        nik_atasan: this.selectedKaryawan,
        kode_unit: this.selectedUnit,
        kode_bagian: this.selectedBagian,
      };
      this.updateBawahan({ formData: formData });
    },
    // mapNikPjs(val) {
    //   if (!val) return undefined;
    //   let arr = val.map(({ nik }) => nik);
    //   return arr.join();
    // },
    resetAll() {
      this.selectedSubtitute = undefined;
      this.availableSubtitute = [];
      this.selectedBagian = undefined;
      this.selectedType = undefined;
      this.selectedKaryawan = undefined;
      this.setBagians(undefined);
      this.setTypes(undefined);
      this.setKaryawans(undefined);
      this.setPenggantiTersedia(undefined);
      this.setPenggantiTerpilih(undefined);
    },
    loadUnits() {
      this.getUnits();
    },
    unitFilter(item, queryText) {
      if (!queryText || !item) return;
      const textOne = item.name.toLowerCase();
      const textTwo = item.kodeunitbagian.toLowerCase();
      const searchText = queryText.toLowerCase();
      return (
        textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
      );
    },
    bagianFilter(item, queryText) {
      if (!queryText || !item) return;
      const textOne = item.name.toLowerCase();
      const textTwo = item.kodebagian.toLowerCase();
      const searchText = queryText.toLowerCase();
      return (
        textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
      );
    },
    typeFilter(item, queryText) {
      if (!queryText || !item) return;
      const textOne = item.name.toLowerCase();
      const textTwo = item.type.toLowerCase();
      const searchText = queryText.toLowerCase();
      return (
        textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
      );
    },
    karyawanFilter(item, queryText) {
      if (!queryText || !item) return;
      const textOne = item.nama.toLowerCase();
      const textTwo = item.jabatan.toLowerCase();
      const searchText = queryText.toLowerCase();
      return (
        textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
      );
    },
    syncWithSimkar(){
      this.$refs.selectunit.focus();
       this.$dialog
        .confirm({
          text: this.$t("msgbox.data-synchronization-will-cause-the-user-to-lose-access-are-you-sure"),
          title: this.$t("msgbox.sync-to-simkar"),
          persistent: true,
          icon: this.iconQuestion,
          color: "danger",
          actions: [
            { text: this.$t("button.cancel"), color: "primary", key: false },
            { text: this.$t("button.yes-proceed"), color: "red", key: true },
          ],
        })
        .then((result) => {
          if (result != true) return;
          //doAxios
        });
    }
  },
};
</script>
<style>
.subordinate--title .v-toolbar__content {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-around;
  align-content: space-between;
}
</style>